import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { i18n } from './i18n';
import { createPinia } from 'pinia'
import { interceptor } from '@dutypay/utilities';
import 'element-plus/theme-chalk/display.css';
import 'element-plus/theme-chalk/el-loading.css';
import './styles.scss';
import PopupManager from 'element-plus';
import DesignSystem from '@dutypay/design-system';
import ComponentsLibrary from '@dutypay/components-library';
import mitt from 'mitt';
import { name, version } from '../package.json';

const emitter = mitt();
const pinia = createPinia()
const app = createApp(App);
app.config.globalProperties.$http = interceptor;
app
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(PopupManager, {
        zIndex:3050 // Notifications z-index
    })
    .use(DesignSystem)
    .use(ComponentsLibrary)
    .mount("#app");

app
    .provide('router', router)
    .provide('emitter', emitter);

console.log( name + " version: ", version)


